import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout-mdx.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Disciplines externes`}</h2>
    <h3>{`Kung-fu traditionnel sino-vietnamien`}</h3>
    <p>{`De son vrai nom Vo-Co-Truyen, c’est un art martial de maitrise du corps et de
ses mouvements dans l’espace. Il se pratique à mains nues, mais aussi avec des
armes traditionnelles telles que bâton, sabre, épée et même nunchaku. Le
kung-fu chinois et le vo-co-truyen vietnamien se ressemblent énormément, tant
dans les postures que parfois dans les mouvements et même les Taos, d’où
l’amalgame niveau technique.`}</p>
    <p>{`A l’opposé, beaucoup font aussi l’amalgame entre le vo-co-Truyen et
Viet-Vo-Dao, ce dernier étant un terme inventé en France ; il s’agit de plus un
style hétéroclite, très carré en cassant dans les mouvements, faisant penser au
technique de karaté.`}</p>
    <h3>{`Arts martiaux mixtes / Free-fight`}</h3>
    <p>{`C’est le sport en vogue (nommé MMA), et en instance de reconnaissance en
France.`}</p>
    <p>{`Il combine du sports de combat et des arts martiaux et l’objectif est d’être
efficace sur toutes les distances durant un combat : pied-poing, projection,
clé. Les entrainements comprennent un travail cardio et renforcement
musculaire.`}</p>
    <p>{`Des règles strictes régissent la pratique et la compétition pour éviter au
maximum les accidents.`}</p>
    <p>{`Nous appartenons à la FFKDA, discipline Karaté Mix.`}</p>
    <h3>{`Self-défense Jeet-Kune-Do`}</h3>
    <p>{`De la philosophie de Bruce Lee est né un style particulier d’art martial, issu
de plusieurs autres arts, notamment le Silat ou Penchak Silat, la boxe
française, le Wing-Chun, le kung-fu et le Kali-Eskrima. Basé surtout sur un
travail d’automatise de placement, de déplacement et de frappe, on dit que le
JKD a été le précurseur du MMA.`}</p>
    <p>{`Pourtant, l’essence du JKD est le combat réel.`}</p>
    <h3>{`Art martial artistique`}</h3>
    <p>{`Appelé aussi Martial Arts Tricking, est une discipline moderne dont l’essence
même est de pousser les mouvements d’arts martiaux jusqu’au summum de
l’esthétisme, en y ajoutant des prouesses techniques, proche de la gymnastique
au sol. Cette discipline se fait mains nues au début, puis avec des armes,
telles que bâton, nunchaku ou sabre.`}</p>
    <h3>{`Kali-Eskrima`}</h3>
    <p>{`Discipline peu connue du grand public, et pourtant reconnaissable dans
énormément de films d’actions, on croit voir du krav-maga par exemple, mais la
base réelle est le Kali-Eskrima.`}</p>
    <p>{`C’est tout d’abord un vrai art martial à part entière, fondée en Asie du Sud
Est Asiatique (Malaysie, Philippines) par des pays qui ont subi les affres de
la guerre sur presque 400 ans.`}</p>
    <p>{`La pratique se fait tout d’abord au stick (bâton court) pour plus de sûreté,
mais en réalité, ce sont soit des couteaux ou des lames. Nous y trouvons aussi
ce que l’on appelle le dirty boxe, très spécifique et reconnaissable.`}</p>
    <h3>{`Sabre-laser`}</h3>
    <p>{`Le sabre laser est une discipline assez jeune, reconnue depuis peu par la
Fédération Française d’Escrime, avec des règles spécifiques. Elle mélange arts
martiaux de tous les horizons (escrime moderne, escrime médieval, kung-fu,
kendo, kenjutsu, kali-eskrima) pour apprendre à manier le sabre si spécifique
de l’univers de Star Wars. Même s’il y a ce côté Geek et ludique (qui n’a
pas rêvé de manier le sabre-laser de Dark Vador !), le côté martial et sportif
est aussi omni-présent ! Que la Force soit avec vous !`}</p>
    <h2>{`Disciplines internes`}</h2>
    <p>{`Le club propose aussi, via 2 autres associations, la pratique des arts
internes.`}</p>
    <h3>{`Qi-Gong`}</h3>
    <p>{`Le Qi-Gong est un travail sur la concentration et la respiration, pour faire le
plein énergétique pour notre corps. Il se base sur des mouvements simples, qui
font circuler les différents énergies dans les méridiens, véritables autoroutes
énergétiques qui traversent tous les membre. De plus il y a un rapport très
étroit avec la médecine chinoise et la santé.`}</p>
    <p>{`M. Tran dispense les cours. Il est l’un des plus haut gradé de la fédération
FAEMC et aussi formateur pour les futurs professeurs dans la discipline depuis
presque 20 ans. Ainsi un grand nombre de ses anciens élèves donne désormais des
cours sur Nice mais aussi dans toute la France. Il pratique la médecine
chinoise et son enseignement est basé sur tous ces concepts.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.arts-energetiques.com"
      }}>{`Découvrez notre site dédié !`}</a></p>
    <h3>{`Tai-Chi-Chuan`}</h3>
    <p>{`Le Tai-Chi-Chuan est un art martial harmonieux avec la nature, par des
mouvements fluides et lent, pour un contrôle du corps mais aussi une
circulation de l’énergie. Les cours sont dispensé par Delphine Tran, multiple
championne de France et médaillée d’Europe.`}</p>
    <p>{`Vous souhaitez en savoir plus ?
`}<a parentName="p" {...{
        "href": "https://www.arts-energetiques.com"
      }}>{`Découvrez notre site dédié !`}</a></p>
    <h3>{`Pilate et Yoga`}</h3>
    <p>{`Le Hatha Yoga est une pratique originaire d’Inde, basé sur des exercices
d’extension et d’étirement qui entretiennent la souplesse du corps et relâche
le mental. Il associe le contrôle du souffle et la relaxation, procurant une
sensation de calme et de bien-être. Le pratiquant constate au bout d’un mois,
une réduction de la résonance des facteurs affectifs et émotionnels,
amélioration de la gestion du stress, qualité de sommeil, etc... (Méthode
d’enseignement FFHY).`}</p>
    <p>{`Le Pilates est une gymnastique douce et ludique qui s’adresse à tous les
publics. Le travail est accès sur le renforcement des muscles profonds à l’aide
d’accessoires, roll up, cercles et élastiques. Il permet de corriger sa
posture, améliore les problèmes de dos et développe la musculature de façon
harmonieuse. A pratiquer sans modération !`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.facebook.com/Aude.Nathalie.JanaYoga/"
      }}>{`Plus d'informations en cliquant
ici`}</a></p>
    <p>{`Nous prenons les élèves dès l’âge de 3 ans selon les disciplines.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      